import type { ReactNode } from 'react';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ErrorDisplay } from '@confluence/error-boundary';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useCreateSpaceExperiment } from '@confluence/create-space/entry-points/createSpace';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { OperationTypes, canPerformSpaceOperation } from '@confluence/entity-operations';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

import { FooterCreateButtonsQuery } from '../queries/FooterCreateButtonsQuery.graphql';
import type {
	FooterCreateButtonsQuery as FooterCreateButtonsQueryType,
	FooterCreateButtonsQueryVariables,
} from '../queries/__types__/FooterCreateButtonsQuery';

import { useCreateFromContentTreeEligible } from './useCreateFromContentTreeEligible';

const ContentTreeOnboardingLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTreeOnboarding" */ './ContentTreeOnboarding'
			)
		).ContentTreeOnboarding,
});

const TreeBottomCreateButtonLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TreeBottomCreateButton" */ './TreeBottomCreateButton'
			)
		).TreeBottomCreateButton,
});

type FooterCreateButtonsProps = {
	spaceKey: string;
	isContentTreeLoading: boolean;
	emptyContent?: ReactNode;
};

export const FooterCreateButtons = ({
	spaceKey,
	isContentTreeLoading,
	emptyContent,
}: FooterCreateButtonsProps) => {
	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();
	const isDatabasesGloballyEnabled = isContentTypeEnabledInCurrentEnv('database');
	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();

	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();

	const {
		cohort: createFromContentTreeCohort,
		isCreateFromContentTreeEnrolled,
		isCreateFromContentTreeEligible,
	} = useCreateFromContentTreeEligible();

	const { data, loading, error } = useQuery<
		FooterCreateButtonsQueryType,
		FooterCreateButtonsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FooterCreateButtonsQuery,
		{
			errorPolicy: 'all',
			variables: {
				spaceKey,
			},
		},
	);

	if (isContentTreeLoading || loading || !data) {
		return null;
	}

	const canCreatePage = canPerformSpaceOperation({
		operationCheckResult: data,
		operation: OperationTypes.CREATE,
		contentType: 'page',
	});

	if (!canCreatePage) {
		return <>{emptyContent}</>;
	}

	const didContainUserContent = data?.space?.didContainUserContent;

	const showContentTreeOnboarding =
		isSpaceTypesCreationFlowEnabled && didContainUserContent === false;
	const showLiveDoc =
		isTwoPageTypesExperience &&
		Boolean(data?.space?.spaceTypeSettings?.enabledContentTypes?.isLivePagesEnabled);
	const showWhiteboard =
		isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
		Boolean(data?.space?.spaceTypeSettings?.enabledContentTypes?.isWhiteboardsEnabled);
	const showDatabase =
		isDatabasesGloballyEnabled &&
		Boolean(data?.space?.spaceTypeSettings?.enabledContentTypes?.isDatabasesEnabled);

	return (
		<>
			{showContentTreeOnboarding ? (
				<ContentTreeOnboardingLoader
					showLiveDoc={showLiveDoc}
					showWhiteboard={showWhiteboard}
					showDatabase={showDatabase}
				/>
			) : (
				emptyContent ||
				(isCreateFromContentTreeEnrolled && (
					<TreeBottomCreateButtonLoader
						cohort={createFromContentTreeCohort}
						isCreateFromContentTreeEligible={isCreateFromContentTreeEligible}
						spaceKey={spaceKey}
						spaceId={data.space?.id || ''}
						source="endOfContentTreeContextualCreate"
					/>
				))
			)}
			{error && <ErrorDisplay error={error} />}
		</>
	);
};
